import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IData } from 'app/core/models/api/data';
import { IError } from 'app/core/models/error/error';
import { IClinic } from 'app/core/models/user/user';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { IGetAllClinicByAuth } from 'app/core/models/clinic/clinic';
import { IMedia, IUploadFile } from 'app/core/models/media/media';

@Injectable({
    providedIn: 'root',
})
export class ClinicService {
    private readonly router = inject(Router);
    private readonly httpClient = inject(HttpClient);
    private readonly authenticationService = inject(AuthenticationService);

    public getOne(clinicId: string = this.authenticationService.clinicId()): Observable<IClinic> {
        const baseUrl = ['clinic', 'admin', 'clinic', clinicId];

        const url = this.router.createUrlTree(baseUrl);

        const parsedUrl = `${environment.apiUrl}/${environment.apiVersion}${url}`;

        return this.httpClient.get(parsedUrl, { responseType: 'json' }).pipe(
            map((res: IData<IClinic>) => {
                return res.data;
            }),
            catchError((error: HttpErrorResponse) => {
                if (error.error instanceof ErrorEvent) {
                    return throwError(() => `Error: ${error.error.message}`);
                }
                const errorApi: IError = error.error.error;
                return throwError(() => errorApi);
            }),
        );
    }

    public getAllWithAuth(parameters: IGetAllClinicByAuth = null): Observable<IData<IClinic[]>> {
        const baseUrl = ['clinic', 'admin', 'clinic'];

        const url = this.router.createUrlTree(baseUrl, {
            queryParams: !!parameters ? parameters : { isHiddenAgreement: false, showOwner: false },
        });

        const parsedUrl = `${environment.apiUrl}/${environment.apiVersion}${url}`;

        return this.httpClient.get<IData<IClinic[]>>(parsedUrl, { responseType: 'json' }).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.error instanceof ErrorEvent) {
                    return throwError(() => `Error: ${error.error.message}`);
                }
                const errorApi: IError = error.error.error;
                return throwError(() => errorApi);
            }),
        );
    }

    uploadLogo(data: IUploadFile): Observable<IData<IMedia>> {
        const { file, ...parameters } = data;
        const baseUrl = ['clinic', 'admin', 'clinic', 'logo'];

        const formData: FormData = new FormData();
        formData.append('file', file);

        const url = this.router.createUrlTree(baseUrl, {
            queryParams: parameters,
        });

        const parsedUrl = `${environment.apiUrl}/${environment.apiVersion}${url}`;

        return this.httpClient.post<IData<IMedia>>(parsedUrl, formData).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.error instanceof ErrorEvent) {
                    return throwError(() => `Error: ${error.error.message}`);
                }
                const errorApi: IError = error.error.error;
                return throwError(() => errorApi);
            }),
        );
    }
}
